/**
 * 
 *  Tools
 * 
 */

import ReactGA from 'react-ga';
import store2 from 'store2';
import Cookies from 'universal-cookie';
import axios from 'axios';

const trackingCode = 'UA-69942933-2';

export function logPageView(page) {
    const cookieConsent = store2.get('cookieConsent');
    const consentSettings = getCookie('consent-settings').split(',');

    if (cookieConsent &&
        consentSettings.includes('necessary') &&
        consentSettings.includes('statistics')) {
        ReactGA.initialize(trackingCode);
        ReactGA.set({ page });
        ReactGA.pageview(page);
    }
}


export function setAnalyticsSessionId(id) {
    ReactGA.initialize(trackingCode);
    ReactGA.ga('set', 'userId', id);
}



export function setAnalyticsEvent(category, action, label) {
    ReactGA.initialize(trackingCode);
    ReactGA.event({
        category,
        action,
        label
    });
}


export function getCookie(name) {
    if (!name)
        return false;

    const cookies = new Cookies();
    return cookies.get(name);
}

export async function convertHTMLtoPDF(html) {

    if (!html)
        return false;


    const options = {
        method: 'POST',
        url: 'https://api2pdf-api2pdf-v1.p.rapidapi.com/wkhtmltopdf/html',
        headers: {
            'content-type': 'application/json',
            'x-rapidapi-key': 'efee3c2fadmsh78d354418ad6593p15bbfajsnc84e4096a7c0',
            'x-rapidapi-host': 'api2pdf-api2pdf-v1.p.rapidapi.com'
        },
        data: {
            html,
            inlinePdf: true,
            fileName: 'wkhtmltopdf-html-to-pdf.pdf',
            options: { orientation: 'portrait', pageSize: 'A4' }
        }
    };

    return await axios.request(options).then(function (response) {
        return response.data;
    }).catch(function (error) {
        console.error(error);
    });
}


export function convertDataURI(data) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    })
}


export function sharePDF (pdf) {
    if(!pdf)
        return false;
        
    if (navigator && navigator.share) {
        navigator.share({
            title: 'Rockwool',
            url: pdf
        })
    }
}
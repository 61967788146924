/**
 * 
 * Cookie Selection Page
 * 
 */


import React, { useState } from 'react';
import { Grid, Typography, Button, Table, TableHead, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Styles from "./styles";
import LayoutModal from '../../components/LayoutModal';
import CheckboxField from '../../components/CheckboxField';

export default function ConsentSettings(props) {

    const classes = Styles();
    const [analyticsData, setAnalyticsData] =
            useState({ necessary: true, statistics: true });

    const handleChange = (e) => {
        if (e && e.target && e.target.id)
            setAnalyticsData(Object.assign({}, analyticsData, { [e.target.id]: e.target.checked }))
    }

    return (<Grid container className={classes.root} id={'c-container'}>
        <LayoutModal
            closeText="Abbrechen"
            childrenStyle={{overflow: 'hidden'}}
            {...props}>
            <Grid item xs={12} className={classes.settingsContainer}>
                <Grid container>
                    <Grid item xs={12} className={classes.section}>
                        <Typography variant="body1">
                        <h2 class="nutzungbestimmungen">Cookie-Auswahl</h2>{/* ArmanI. #136 Umlaute */}
                            Wir verwenden Cookies, um Inhalte und Anzeigen zu
                            personalisieren, Funktionen für soziale Medien
                            anbieten zu können und die Zugriffe auf unsere
                            Website zu analysieren. Außerdem geben wir
                            Informationen zu Ihrer Verwendung unserer Website an
                            unsere Partner für soziale Medien, Werbung und
                            Analysen weiter. Unsere Partner führen diese
                            Informationen möglicherweise mit weiteren Daten
                            zusammen, die Sie ihnen bereitgestellt haben oder die
                            sie im Rahmen Ihrer Nutzung der Dienste gesammelt
                            haben. Sie geben Einwilligung zu unseren Cookies,
                            wenn Sie unsere Webseite weiterhin nutzen.
                        </Typography> 
                        {/* ArmanI. #33 Umlaute, Noch Änderung Außerdem statt SuBerdem*/}
                    </Grid>
                    <Grid item xs={12}>
                      {/*<Typography variant="subtitle1">
                            Notwendig (4)
                        </Typography>*/}
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table aria-label="spanning table" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>Name</b></TableCell>
                                        <TableCell><b>Kategorie</b></TableCell>
                                        <TableCell><b>Anbieter</b></TableCell>
                                        <TableCell><b>Zweck</b></TableCell>
                                        <TableCell><b>Ablauf</b></TableCell>
                                        <TableCell><b>Typ</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>consent-settings</TableCell>
                                        <TableCell>Notwendig
                                        </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>
                                            Speichert den Zustimmungsstatus des
                                            Benutzers für Cookies auf der
                                            aktuellen Domäne.
                                        </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>_ga</TableCell>
                                        <TableCell>Statistiken</TableCell>
                                        <TableCell>
                                            <Link href="https://policies.google.com/privacy">
                                            Google
                                            </Link>
                                        </TableCell>
                                        <TableCell>Registriert eine eindeutige ID, die verwendet wird, 
                                            um statistische Daten dazu, wie der Besucher die Website nutzt, 
                                            zu generieren.
                                        </TableCell>
                                        <TableCell className="cookieJahr">2 Jahre</TableCell>
                                        <TableCell>HTTP</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>_gat</TableCell>
                                        <TableCell>Statistiken</TableCell>
                                        <TableCell>
                                            <Link href="https://policies.google.com/privacy">
                                            Google
                                            </Link>
                                        </TableCell>
                                        <TableCell>Wird von Google Analytics verwendet, 
                                            um die Anforderungsrate einzuschränken
                                        </TableCell>
                                        <TableCell>1 Tag</TableCell>
                                        <TableCell>HTTP</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>_gid</TableCell>
                                        <TableCell>Statistiken</TableCell>
                                        <TableCell>
                                            <Link href="https://policies.google.com/privacy">
                                            Google
                                            </Link>
                                        </TableCell>
                                        <TableCell>Registriert eine eindeutige ID, 
                                            die verwendet wird, um statistische Daten dazu, 
                                            wie der Besucher die Website nutzt, zu generieren.
                                        </TableCell>
                                        <TableCell>1 Tag</TableCell>
                                        <TableCell>HTTP</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9} lg={6} style={{margin: '5px'}}>
                        <Grid container direction="row">
                            <Grid item xs={6} sm={3}>
                                <CheckboxField
                                    id="necessary"
                                    label={`Notwendig`}
                                    disabled
                                    value={analyticsData['necessary']}
                                    onChange={handleChange}
                                />
                            </Grid>
                          {/*  <Grid item xs={6} sm={3}>
                                <CheckboxField
                                    id="marketing"
                                    label={`Marketing`}
                                    value={analyticsData['marketing']}
                                    onChange={handleChange}
                                />

                            </Grid>*/}
                            <Grid item xs={6} sm={3}>
                                <CheckboxField
                                    id="statistics"
                                    label={`Statistiken`}
                                    value={analyticsData['statistics']}
                                    onChange={handleChange}
                                />
                            </Grid>
                             {/* <Grid item xs={6} sm={3}>
                                <CheckboxField
                                    id="preferences"
                                    label={`Präferenzen`}
                                    value={analyticsData['preferences']}
                                    onChange={handleChange}
                                />
                            </Grid>*/}
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.footer} id="c-footer-1">{/*ArmanI. #136 Id*/}
                <Link to={
                    {

                        pathname: '/',
                        state: {
                            consentSettings: false,
                            analyticsData
                        }
                    }
                }>
                    <Button
                        id="c_submit-1"
                        variant="contained"
                        color="primary"
                        className={classes.button}>
                        Auswahl speichern
                    </Button>
                    {/*ArmanI. #136 Id*/}
                </Link>
            </Grid>
        </LayoutModal>
    </Grid >)

}
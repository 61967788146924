/**
 *
 * Consent Page
 *
 */


import React, { useState } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Styles from "./styles";
import LayoutModal from '../../components/LayoutModal';
import CheckboxField from '../../components/CheckboxField';
import store2 from 'store2';
import Cookies from 'universal-cookie';

export default function Consent(props) {

    const classes = Styles();
    const [privacy, setPrivacy] = useState(true);
    const [cookieConsent, setcookieConsent] = useState(true);

    const handleSubmit = () => {
        store2.set('cookieConsent', cookieConsent);
        store2.set('privacy', privacy);
        const cookies = new Cookies();
        const settings = cookies.get('consent-settings');

        if (cookieConsent === true) {
            let consent = ['necessary'];

            if (props.location.state && props.location.state.hasOwnProperty('analyticsData')) {
                // if the user submitted cookie settings
                for (const [setting, enabled] of Object.entries(props.location.state.analyticsData)) {
                    if (enabled && !consent.includes(setting)) {
                        consent.push(setting);
                    }
                }

                cookies.set('consent-settings', consent.join(','));
            } else {
                // set default checks if the user didn't open the cookie settings
                cookies.set('consent-settings', 'necessary,statistics');
            }
        } else {
            // only use necessary cookies if the user didn't consent
            cookies.set('consent-settings', 'necessary');
        }
    }

    return (<Grid container className={classes.root} id={'consent-container'}>
        <LayoutModal
            disableClose
            {...props}>
            <Grid item xs={12} className={classes.section}>
                {/*<Typography variant="body1">*/}
                {<h2 className="nutzungbestimmungen">Nutzungsbedingungen</h2>}
                   {/* Die Nutzungsbedingungen regeln das Verhältnis zwischen Ihnen und der DEUTSCHE ROCKWOOL GmbH & Co. KG
                    hinsichtlich der Verwendung unseres Bildmaterials, unseres Logos, unserer Videos, unserer Marke und
                   unserer Website.*/}
                {/*</Typography>*/}
            </Grid>
            <Grid item xs={12} className={classes.section}>
                <Grid container>
                    <Grid item xs={12} style={{marginLeft: '10px'}}>
                        <CheckboxField
                            id="privacy"
                            label={<div>
                                Bei der Nutzung des Einspar-Ratgebers erkläre ich mich mit den <a
                                href="https://www.rockwool.com/de/downloads-und-services/tools-und-rechner/einsparratgeber/nutzungsbedingungen/"
                                target="_blank">Nutzungsbedinungen</a> einverstanden.
                            </div>}
                            value={privacy}
                            onChange={(e) => setPrivacy(e.target.checked)}
                        />
                    </Grid>
                    <Grid item xs={12} style={{marginLeft: '10px'}}>
                        <CheckboxField
                            id="cookieConsent"
                            label={<div>Ich stimme meiner <Link rel="canonical" to={{
                                pathname: '/',
                                state: {
                                    consentSettings: true
                                }
                            }}>Cookie-Auswahl</Link> zu und willige der Verwendung von Cookies ein. Meine Auswahl kann
                                ich nachträglich jederzeit im Datenschutz-Menü anpassen.*</div>}
                            value={cookieConsent}
                            onChange={(e) => setcookieConsent(e.target.checked)}
                        />
                        <Typography variant="body1" style={{textAlign: 'right', marginRight: '16px'}}>
                            <span>*Pflichtfelder</span>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.footer} id="c-footer">
                <Link rel="canonical" to="/">
                    <Button id="c_submit"
                            onClick={() => privacy ? handleSubmit() : false}
                            disabled={!privacy}
                            variant="contained"
                            color="primary"
                            className={classes.button}>
                        Speichern
                    </Button>
                    {/*ArmanI. #136 Id*/}
                </Link>
            </Grid>
        </LayoutModal>
    </Grid>)

}
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import { BrowserRouter as Router } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme.js';

import Loader from './pages/Loader';

import { Provider } from 'react-redux';

import createStore from './store';

import store2 from 'store2';

const initialState = {
    version: '1.0',
    name: 'ROCKWOOL',
    lang: 'Germany',
    practice: {},
    product: {},
    user: {},
    postalCode: [],
    loading: false,
    error: false,
    success: false,
    notification: false
}

const localStore = store2.get('rockwoolData');

const store = createStore(localStore ? localStore : initialState);

store.subscribe(() => {
   // console.log('store', store.getState());
    store2.set('rockwoolData', store.getState());
})

ReactDOM.render(
    <Router>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <Suspense fallback={<Loader />}>
                    <App />
                </Suspense>
            </Provider>
        </ThemeProvider>
    </Router>, document.getElementById('root'));

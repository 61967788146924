
/*
 * 
 * Notification 
 * 
 */


import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';


export default function Notification({ severity, show, text, handleClose }) {
    return (<div>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={show} autoHideDuration={4000} onClose={handleClose}>
            <Alert elevation={6} variant="filled" onClose={handleClose} severity={severity}>
                {text}
            </Alert>
        </Snackbar>
    </div>)
}




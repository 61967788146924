

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: 'Nunito-Regular',
        '@global': {
            '.MuiFormControlLabel-root': {
                alignItems: 'flex-start',
                marginTop: '15px'
            },
            '.MuiIconButton-root': {
                paddingTop: '0px'
            }
        }
    },
    formControlLabel: {
        marginTop: theme.spacing(2),
    },
    textSize: {
        fontSize: '15px',
    },
    error: {
        fontSize: '15px',
        color: 'red'
    }
}));


export default useStyles;
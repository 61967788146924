/**
 * 
 * Checkbox Field
 * 
 */

import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { FormControlLabel, Checkbox } from '@material-ui/core';
import Styles from './styles';


const CheckboxWithGreenCheck = withStyles({
    root: {
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0,
            border: '1px solid black',
            borderRadius: '4px',
            backgroundColor: 'white'
        },
        "&$checked": {
            "& .MuiIconButton-label": {
                position: "relative",
                zIndex: 0,
                border: '1px solid black',
                borderRadius: '4px',
                backgroundColor: 'white'
            },
            "& .MuiIconButton-label:after": {
                content: '""',
                left: 4,
                top: 4,
                height: 15,
                width: 15,
                position: "absolute",
                backgroundColor: "black",
                zIndex: -1
            }
        }
    },
    checked: {}
})(Checkbox);

const CheckboxWithDisabledGreenCheck = withStyles({
    root: {
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0,
            border: '1px solid black',
            borderRadius: '4px',
            backgroundColor: 'white'
        },
        "&$checked": {
            "& .MuiIconButton-label": {
                position: "relative",
                zIndex: 0,
                border: '1px solid rgba(0, 0, 0, 0.38)',
                borderRadius: '4px',
                backgroundColor: 'white'
            },
            "& .MuiIconButton-label:after": {
                content: '""',
                left: 4,
                top: 4,
                height: 15,
                width: 15,
                position: "absolute",
                backgroundColor: "rgba(0, 0, 0, 0.38)",
                zIndex: -1
            }
        }
    },
    checked: {}
})(Checkbox);


export default function CheckboxField({ value, onChange, id, disabled, label, required, styles, warning, error }) {

    const classes = Styles();
    const setStyles = label === 'Remember Me' ? classes.formControlLabel : null;
    const setFontSize = label !== 'Remember Me' ? classes.textSize : null;
    const Component = disabled ?  CheckboxWithDisabledGreenCheck : CheckboxWithGreenCheck;

    return (
        <div className={classes.root}>
            <FormControlLabel className={setStyles}
                              control={<Component
                                  id={id}
                                  disabled={disabled}
                                  style={styles ? styles : { color: "white" }}
                                  checked={value || false}
                                  onChange={onChange} />}
                              label={<span className={setFontSize} >{label}</span>} />
            <div className={classes.error}>
                {(error && <span>{error}</span>) || (warning && <span>{warning}</span>)}
            </div>
        </div>

    )
}

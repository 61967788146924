/**
 * 
 * Remotes File
 * 
 * 
 */

import postalCode from '../utils/postalData.json';


/**
 * 
 * @param {object} record 
 * @returns 
 */
export const saveProductModal = (record) => {
    return Promise.resolve(record);
}


/**
 * 
 * @param {object} record 
 * @returns 
 */
export const savePracticeModal = (record) => {
    return Promise.resolve(record);
}


/**
 * 
 * @param {object} record 
 * @returns 
 */
export const saveUserModal = (record) => {
    return Promise.resolve(record);
}


export const fetchPostalCodeModal = () => {
    return Promise.resolve(postalCode);
}
/**
 * 
 * 
 * Selector File
 * 
 */


/**
 * Select Domain Data
 * @returns 
 */
export const selectDomain = () => (state) => state.name || false;


/**
 * Select Language
 * @returns 
 */
export const selectLanguage = () => (state) => state.lang || false;


/**
 * 
 * Select Product Data
 * @returns 
 */
export const selectProduct = () => (state) => state.product || {};


/**
 * 
 * Select Practice Data
 * @returns 
 */
export const selectPractice = () => (state) => state.practice || {};

/**
 * 
 * Select User Data
 * @returns 
 */
export const selectUser = () => (state) => state.user || {};


/**
 * 
 * Select Postal Code Data
 * @returns 
 */
export const selectPostalCode = () => (state) => state.postalCode || [];



/**
* 
* Select Error
* @returns 
*/
export const selectError = () => (state) => state.error || false;


/**
* 
* Select Success
* @returns 
*/
export const selectSuccess = () => (state) => state.success || false;



/**
* 
* Select Notification
* @returns 
*/
export const selectNotification = () => (state) => state.notification || false;


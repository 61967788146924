/**
 * 
 * 
 * Reducers File
 * 
 * 
 */

import {
    SAVE_PRODUCT_SUCCESS,
    SAVE_PRODUCT_ERROR,
    SAVE_PRACTICE_SUCCESS,
    SAVE_PRACTICE_ERROR,
    SAVE_USER_SUCCESS,
    SAVE_USER_ERROR,
    FETCH_POSTAL_CODE_SUCCESS,
    FETCH_POSTAL_CODE_ERROR,
    CLEAR_CACHE,
    SHOW_NOTIFICATION,
    CLEAR_NOTIFICATION
} from './constants';


export default function Reducers(state = {}, actions) {
    const { type, record = {}, records = [], error, data } = actions || {};
    switch (type) {
        case SAVE_PRODUCT_SUCCESS:
            return Object.assign({}, state, { product: Object.assign({}, state.product, record) });
        case SAVE_PRACTICE_SUCCESS:
            return Object.assign({}, state, { practice: record });
        case SAVE_USER_SUCCESS:
            return Object.assign({}, state, { user: record });
        case FETCH_POSTAL_CODE_SUCCESS:
            return Object.assign({}, state, { postalCode: records });
        case CLEAR_CACHE:
            return Object.assign({}, state, { practice: {}, user: {}, postalCode: [], error: false, success: false })
        case SHOW_NOTIFICATION:
            return Object.assign({}, state, { notification: data });
        case CLEAR_NOTIFICATION:
            return Object.assign({}, state, { notification: false });
        case SAVE_PRODUCT_ERROR:
        case SAVE_PRACTICE_ERROR:
        case SAVE_USER_ERROR:
        case FETCH_POSTAL_CODE_ERROR:
        default:
            return state;
    }

}

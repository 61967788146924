/**
 * 
 * 
 * Constants File
 * 
 */


/**
 * 
 * Save Product Constants
 */
export const SAVE_PRODUCT = 'app/rockwool/SAVE_PRODUCT';
export const SAVE_PRODUCT_SUCCESS = 'app/rockwool/SAVE_PRODUCT_SUCCESS';
export const SAVE_PRODUCT_ERROR = 'app/rockwool/SAVE_PRODUCT_ERROR';

/**
 * Save Practice Constants
 */
export const SAVE_PRACTICE = 'app/rockwool/SAVE_PRACTICE';
export const SAVE_PRACTICE_SUCCESS = 'app/rockwool/SAVE_PRACTICE_SUCCESS';
export const SAVE_PRACTICE_ERROR = 'app/rockwool/SAVE_PRACTICE_ERROR';

/**
 * Save User Constants
 */
export const SAVE_USER = 'app/rockwool/SAVE_USER';
export const SAVE_USER_SUCCESS = 'app/rockwool/SAVE_USER_SUCCESS';
export const SAVE_USER_ERROR = 'app/rockwool/SAVE_USER_ERROR';


/**
 * Fetch Postal Code Constants
 */
export const FETCH_POSTAL_CODE = 'app/rockwool/FETCH_POSTAL_CODE';
export const FETCH_POSTAL_CODE_SUCCESS = 'app/rockwool/FETCH_POSTAL_CODE_SUCCESS';
export const FETCH_POSTAL_CODE_ERROR = 'app/rockwool/FETCH_POSTAL_CODE_ERROR';


 
/**
 * Clear Cache Constants
 */
 export const CLEAR_CACHE = 'app/rockwool/CLEAR_CACHE';


 
/**
 * Show and clear notification
 */
 export const SHOW_NOTIFICATION = 'app/rockwool/SHOW_NOTIFICATION';
 export const CLEAR_NOTIFICATION = 'app/rockwool/CLEAR_NOTIFICATION';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: 'Nunito-Regular',
        minHeight: '100vh',
        backgroundColor: '#D20014',
        height: '100%',
        width: '100%',
        color: 'white'
    },
    logo: {
        width: '35px',
        height: '35px',
        paddingRight: '8px'
    }
}));


export default useStyles;

/**
 * 
 *  Modal Record Form
 * 
 */

import React from 'react';
import { Backdrop, Modal, Paper, Fade, Grid, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Cross from '../../images/icons/Cross.svg';
import Styles from './styles';

export default function Layout(props) {
    const { Title, onOpen, className, style, history, children, disableClose, closeText, childrenStyle , open = true, onClose = null} = props;
    const classes = Styles();

    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up('sm'));

    const closeModal = () => {
        history.goBack();
    }
	
	 var closeFunc = onClose?onClose:!disableClose ? closeModal : null;
	 var nopen = open?open:false;	 
	 
	 var addClass = '';
	 if(!nopen)
	 {
		classes.modal+=' modalclose'; 
	 }
	 else{	
		classes.modal = classes.modal.replaceAll('modalclose','');	
	 }
	 
	classes.title+=' modaltitle';
    classes.body+=' mbody';
	
    return <Grid container className={className} style={style}>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={true}
            disablePortal = {true}
            keepMounted = {true}
            className={classes.modal}
            onClose={closeFunc}
            closeAfterTransition
            BackdropComponent={Backdrop}
            onRendered={onOpen}
            BackdropProps={{
                timeout: 500,
            }}>
            <Fade in={true}>
                <Paper  id="modalwindow"  className={classes.paper} style={!sm ? { width: '92%' } : {}}>
                    {!disableClose ? <Grid container className={classes.close} justifyContent="flex-end" alignItems="center">
                        {closeText ? <Typography variant="subtitle2" onClick={closeFunc}>
                                {closeText}
                            </Typography> :
                            <Typography variant="subtitle2" onClick={closeFunc}>
                                Schlie&szlig;en
                            </Typography>}
                        <img src={Cross} alt="Cross" onClick={closeFunc} className={classes.crossIcon} />
                    </Grid> : null}
                    <Grid container className={classes.layout}>
                        <Grid item xs={12}>
                            <Grid container justifyContent='flex-start' className={classes.title}>
                                <h2 > {Title} </h2>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container className={classes.body} style={childrenStyle || {}}>
                                {children}
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Fade>
        </Modal>
    </Grid>
}

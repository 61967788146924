import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        //Write Root Style here
        fontFamily: 'Nunito-Regular',
    },
    section: {
        marginBottom: '15px',
        '& > h6': {
            fontSize: '14px',
            fontFamily: 'Nunito-ExtraBold',
            marginBottom: '10px'
        },
        '& > p': {
            fontSize: '12px',
            color: '#7b7b7b'
        }
    },
    category: {
        width: '48%',
        height: '70px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '12px',
        borderRadius: '4px',
        '& > p': {
            fontSize: '12px'
        }
    },
    selection: {
        marginBottom: '15px',
        '& > p': {
            marginLeft: '10px',
            width: '85%'
        }
    },
    footer: {
        textAlign: 'center',
        '& > a': {
            textDecoration: 'none'
        }
    },
    button: {
        marginTop: '20px',
        fontFamily: 'Nunito-ExtraBold',
        textTransform: 'none',
        outline: 'none',
        width: '99%',
        borderRadius: '5px',
        background: '#7dbc3d',
        boxShadow: 'none',
        color: '#ffffff',
        height: '48px',
        '&:hover': {
            background: '#ffffff'
        }
    },
    settingsContainer: {
        maxHeight: '345px',
        overflow: 'auto'
    }
}));


export default useStyles;
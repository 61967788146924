/**
 *
 * Loader Page
 *
 */
import React from 'react';
import { Grid } from '@material-ui/core';
import Styles from "./styles";

export default function Loader() {

    const classes = Styles();

    return (<Grid container className={classes.root} alignItems="center">
        <Grid item xs={12}>
            <Grid container alignItems="center" justifyContent="center">
                <div className="spinner-container">
                    <div className="spinner-circle"></div>
                </div>
            </Grid>
        </Grid>
    </Grid>)
}

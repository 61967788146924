

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        border: 'none',
        background: 'transparent',
        padding: '0px',
        minWidth: '40%',
        outline: 'none',
        width: '92%',
        minHeight: '78%',
        boxShadow: 'none'
    },
    layout: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: '15px',
        borderRadius: '8px'
    },
    body: {
        marginTop: '15px',
        maxHeight: '452px',
        overflow: 'auto'
    },
    title: {
        fontSize: '22px',
        '& > h6': {
            fontSize: '22px',
            fontFamily: 'Nunito-ExtraBold'
        }
    },
    close: {
        cursor: 'pointer',
        color: 'white',
        marginBottom: '15px',
        '& > h6': {
            fontSize: '15px',
            fontFamily: 'Nunito-ExtraBold'
        }
    },
    crossIcon: {
        width: '25px',
        height: '25px'
    }
}));


export default useStyles;
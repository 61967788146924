/**
 *
 * Header Links
 *
 */


/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, ListItemText, Typography } from "@material-ui/core";

// @material-ui/icons
import { StyledListItem } from './styles';
import styles from "./styles";

import history from '../../utils/history';

const useStyles = makeStyles(styles);

export default function HeaderLinks({ onClose }) {

    const classes = useStyles();

    return (
        <List className={classes.list}>
            <ListItem className={classes.listItem}>
                <Typography varinat="subtitle1" className={classes.listTitle} /*onClick={() => history.goBack()}*/>
                    Einspar-Ratgeber
                </Typography>
                <Link
                    to={'/manual'}
                    onClick={onClose}
                    className={classes.link}>
                    <StyledListItem
                        button
                        key={'Anleitung'}>
                        <ListItemText style={{padding: '0px'}} className={classes.listItemText} primary={'Anleitung'} />
                    </StyledListItem>
                </Link>
                <Link
                    to={'/funding'}
                    onClick={onClose}
                    className={classes.link}>
                    <StyledListItem
                        button
                        key={'Fordermittel'}>
                        <ListItemText style={{paddingTop: '0px'}} className={classes.listItemText} primary={'Fördermittel'} />
                    </StyledListItem>
                </Link>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Typography varinat="subtitle1" className={classes.listTitle}>
                    Rechtliches
                </Typography>
                <Link
                    to={'/impressum'}
                    onClick={onClose}
                    className={classes.link}>
                    <StyledListItem
                        button
                        key={'Impressum'}>
                        <ListItemText style={{padding: '0px'}} className={classes.listItemText} primary={'Impressum'} />
                    </StyledListItem>
                </Link>
                <Link
                    to={'/datenschutz'}
                    onClick={onClose}
                    className={classes.link}>
                    <StyledListItem
                        button
                        key={'Datenschutz'}>
                        <ListItemText style={{padding: '0px'}} className={classes.listItemText} primary={'Datenschutz'} />
                    </StyledListItem>
                </Link>
                <Link
                    to={'/nutzungsbedingungen'}
                    onClick={onClose}
                    className={classes.link}>
                    <StyledListItem
                        button
                        key={'Nutzungsbedingungen'}>
                        <ListItemText style={{paddingTop: '0px'}} className={classes.listItemText} primary={'Nutzungsbedingungen'} />
                    </StyledListItem>
                </Link>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Link
                    to={{ pathname: "https://www.rockwool.com/de/" }} style={{'text-decoration': 'none'}} target="_blank"
                    onClick={onClose}
                    className={classes.link}>
                    <StyledListItem
                        button
                        key={'ROCKWOOL'}>
                        <ListItemText id='rockwool-link' style={{paddingTop: '0px'}}
                                      className={classes.listItemText} primary={'ROCKWOOL'} />
                    </StyledListItem>
                 </Link>

            </ListItem>
        </List>
    );
}

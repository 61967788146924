/**
 * 
 * Store File
 * 
 * 
 */


import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import Reducers from './reducers';



export default function Store(initialState) {

    const store = createStore(
        Reducers,
        initialState,
        applyMiddleware(thunk)
    );

    return store;
}

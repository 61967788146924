/**
 * 
 * Main Page Header
 * 
 */


import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { Grid, Typography } from '@material-ui/core';
import Drawer from "@material-ui/core/Drawer";
import HeaderLinks from '../HeaderLinks';
import Menu from '../../images/icons/Menu.svg';
import Backspace from '../../images/icons/Arrow-Thin-Left.svg';
import ROCKWOOL_logo_neg from '../../images/icons/ROCKWOOL_logo_neg.svg';
import Cross from '../../images/icons/Cross-black.svg';
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function Header(props) {

    const { color, fixed, absolute, history, location } = props;
    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    React.useEffect(() => {
        if (props.changeColorOnScroll) {
            window.addEventListener("scroll", headerColorChange);
        }
        return function cleanup() {
            if (props.changeColorOnScroll) {
                window.removeEventListener("scroll", headerColorChange);
            }
        };
    });

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const headerColorChange = () => {
        const { color, changeColorOnScroll } = props;
        const windowsScrollTop = window.pageYOffset;
        if (windowsScrollTop > changeColorOnScroll.height) {
            document.body
                .getElementsByTagName("header")[0]
                .classList.remove(classes[color]);
            document.body
                .getElementsByTagName("header")[0]
                .classList.add(classes[changeColorOnScroll.color]);
        } else {
            document.body
                .getElementsByTagName("header")[0]
                .classList.add(classes[color]);
            document.body
                .getElementsByTagName("header")[0]
                .classList.remove(classes[changeColorOnScroll.color]);
        }
    };

    const appBarClasses = classNames({
        [classes.appBar]: true,
        [classes[color]]: color,
        [classes.absolute]: absolute,
        [classes.fixed]: fixed
    });

    const handleBack = () => {
        if (location && location.pathname.indexOf('report') > -1) {
            history.push({
                pathname: '/calculator'
            });
        } else if (location && location.pathname.indexOf('calculator') > -1) {
            history.push({
                pathname: '/'
            });
        } else {
            history.goBack();
        }
    }


    return (
        <AppBar className={appBarClasses}>
            <Toolbar className={classes.container}>
                <div className={classes.flex}>
                    {location && (location.pathname === '/' || location.pathname.indexOf('calculator') > -1 || location.pathname.indexOf('funding') > -1 || location.pathname.indexOf('manual') > -1 || location.pathname.indexOf('product') > -1) ?
                        <Grid className={classes.text} container alignItems="center" direction="row" justifyContent="center">
                            <img src={ROCKWOOL_logo_neg} alt="logo" className={classes.logo} />
                        </Grid> : <Grid container alignItems="center" onClick={handleBack} style={{ cursor: 'pointer' }}>
                            <img src={Backspace} alt="Backspace" className="icons" />
                            <Typography variant="subtitle1" style={{ fontSize: '16px', marginLeft: '10px' }}>Zur&uuml;ck</Typography>
                        </Grid>}
                </div>
                <IconButton
                    style={{ color: '#fff', fontWeight: 'bold', padding: '0px' }}
                    aria-label="open drawer"
                    onClick={handleDrawerToggle}>
                    <img src={Menu} alt="menu" className="icons" />
                </IconButton>
            </Toolbar>
            <Drawer
                variant="temporary"
                anchor={"right"}
                open={mobileOpen}
                classes={{
                    paper: classes.drawerPaper
                }}
                onClose={handleDrawerToggle}>
                <div className={classes.appResponsive}>
                    <Grid container className={classes.close} justifyContent="flex-end" alignItems="center">
                        <Typography variant="subtitle2" onClick={handleDrawerToggle}>
                            Schlie&szlig;en
                        </Typography>
                        <img src={Cross} alt="Cross-black" onClick={handleDrawerToggle} className={classes.crossIcon} />
                    </Grid>
                    {<HeaderLinks onClose={handleDrawerToggle} />}
                </div>
            </Drawer>
        </AppBar>
    );
}

Header.defaultProp = {
    color: "white"
};

Header.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "transparent",
        "white",
        "rose",
        "dark"
    ]),
    rightLinks: PropTypes.node,
    leftLinks: PropTypes.node,
    brand: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    fixed: PropTypes.bool,
    absolute: PropTypes.bool,
    changeColorOnScroll: PropTypes.shape({
        height: PropTypes.number.isRequired,
        color: PropTypes.oneOf([
            "primary",
            "info",
            "success",
            "warning",
            "danger",
            "transparent",
            "white",
            "rose",
            "dark"
        ]).isRequired
    })
};

/**
 * 
 * Error File
 * 
 * 
 */


export const SAVE_PRODUCT_DATA_ERROR = 'Sorry, We are not able to save your product.';

export const SAVE_PRACTICE_DATA_ERROR = 'Sorry, We are not able to save your practice.';

export const SAVE_USER_DATA_ERROR = 'Sorry, We are not able to save your user.';

export const FETCH_POSTAL_CODE_DATA_ERROR = 'Sorry, We are not able to fetch postal code data.';

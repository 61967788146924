import React from 'react';
import {
    Switch,
    Route
} from "react-router-dom";

import Header from './components/Header';
import { Container } from '@material-ui/core';
import { routes } from './schema';

const App = (props) => {
    
    return (
        <Container disableGutters>
            <Header
                color="transparent"
                fixed
                changeColorOnScroll={{
                    height: 100,
                    color: "white"
                }}
                {...props}
            />
            <main className="screens">
                <Switch>
                    {(routes || []).map((route, key) => (<Route state={{ test: 'test' }} key={key} {...route} />))}
                </Switch>
            </main>
        </Container>
    );
}

export default App;
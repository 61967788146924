/**
 * 
 * 
 * Actions File
 * 
 */

import {
    SAVE_PRODUCT_SUCCESS,
    SAVE_PRODUCT_ERROR,
    SAVE_PRACTICE_SUCCESS,
    SAVE_PRACTICE_ERROR,
    SAVE_USER_SUCCESS,
    SAVE_USER_ERROR,
    FETCH_POSTAL_CODE_SUCCESS,
    FETCH_POSTAL_CODE_ERROR,
    CLEAR_CACHE,
    SHOW_NOTIFICATION,
    CLEAR_NOTIFICATION
} from './constants';


import {
    saveProductModal,
    savePracticeModal,
    saveUserModal,
    fetchPostalCodeModal
} from './remotes';


import {
    SAVE_PRODUCT_DATA_ERROR,
    SAVE_PRACTICE_DATA_ERROR,
    SAVE_USER_DATA_ERROR,
    FETCH_POSTAL_CODE_DATA_ERROR
} from './errors';



/**
 * 
 * @param {object} record 
 * @returns 
 */
export const saveProduct = (record) => {
    return function (dispatch) {
        return saveProductModal(record).then(
            (res) => dispatch(saveProductSuccess(res)),
            (err) => dispatch(saveProductError(SAVE_PRODUCT_DATA_ERROR)),
        );
    }
}


/**
 * 
 * @param {object} record 
 * @returns 
 */
export const saveProductSuccess = (record) => {
    return {
        type: SAVE_PRODUCT_SUCCESS,
        record
    }
}


/**
 * 
 * @param {string} error 
 * @returns 
 */
export const saveProductError = (error) => {
    return {
        type: SAVE_PRODUCT_ERROR,
        error
    }
}




/**
 * 
 * @param {object} record 
 * @returns 
 */
export const savePractice = (record) => {
    return function (dispatch) {
        return savePracticeModal(record).then(
            (res) => dispatch(savePracticeSuccess(res)),
            (err) => dispatch(savePracticeError(SAVE_PRACTICE_DATA_ERROR)),
        );
    }
}


/**
 * 
 * @param {object} record 
 * @returns 
 */
export const savePracticeSuccess = (record) => {
    return {
        type: SAVE_PRACTICE_SUCCESS,
        record
    }
}


/**
 * 
 * @param {string} error 
 * @returns 
 */
export const savePracticeError = (error) => {
    return {
        type: SAVE_PRACTICE_ERROR,
        error
    }
}






/**
 * 
 * @param {object} record 
 * @returns 
 */
export const saveUser = (record) => {
    return function (dispatch) {
        return saveUserModal(record).then(
            (res) => dispatch(saveUserSuccess(res)),
            (err) => dispatch(saveUserError(SAVE_USER_DATA_ERROR)),
        );
    }
}


/**
 * 
 * @param {object} record 
 * @returns 
 */
export const saveUserSuccess = (record) => {
    return {
        type: SAVE_USER_SUCCESS,
        record
    }
}


/**
 * 
 * @param {string} error 
 * @returns 
 */
export const saveUserError = (error) => {
    return {
        type: SAVE_USER_ERROR,
        error
    }
}




export const fetchPostalCode = () => {
    return function (dispatch) {
        return fetchPostalCodeModal().then(
            (res) => dispatch(fetchPostalCodeSuccess(res)),
            (err) => dispatch(fetchPostalCodeError(FETCH_POSTAL_CODE_DATA_ERROR)),
        );
    }
}


/**
 * 
 * @param {object} records
 * @returns 
 */
export const fetchPostalCodeSuccess = (records) => {
    return {
        type: FETCH_POSTAL_CODE_SUCCESS,
        records
    }
}


/**
 * 
 * @param {string} error 
 * @returns 
 */
export const fetchPostalCodeError = (error) => {
    return {
        type: FETCH_POSTAL_CODE_ERROR,
        error
    }
}



export const clearCache = () => {
    return {
        type: CLEAR_CACHE
    }
}






/**
 * 
 * @param {object} data 
 * @returns 
 */
export const showNotification = (data) => {
    return {
        type: SHOW_NOTIFICATION,
        data
    }
}



export const clearNotification = () => {
    return {
        type: CLEAR_NOTIFICATION
    }
}

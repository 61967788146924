

import { createMuiTheme } from '@material-ui/core/styles';




const theme = createMuiTheme({
    palette: {
        primary: {
            fontFamily: 'Nunito-Regular',
            main: '#D20014'
        },
        secondary: {
            fontFamily: 'Nunito-Regular',
            main: '#D20014'
        }
    },
    button: {
        fontFamily: 'Nunito-Regular',
        textTransform: 'none'
    },
    typography: {
        fontFamily: 'Nunito-Regular',
        "fontSize": 14,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500
    },
    '& > body': {
        fontFamily: 'Nunito-Regular'
    },
    checkbox: { checkedColor: 'yellow' }
});



export default theme;
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: 'Nunito-Regular',
        minHeight: '100vh',
        backgroundColor: '#D20014',
        height: '100%',
        width: '100%',
        color: 'white'
    },
    logo: {
        width: '100%',
        height: '90px',
        paddingRight: '8px'
    },
    text: {
        '& > h6': {
            fontSize: '26px',
            textAlign: 'center',
            fontFamily: 'Nunito-ExtraBold',
            textTransform: 'uppercase'
        }
    }
}));


export default useStyles;